import { render, staticRenderFns } from "./amount.vue?vue&type=template&id=243097c5&scoped=true&"
import script from "./amount.vue?vue&type=script&lang=js&"
export * from "./amount.vue?vue&type=script&lang=js&"
import style0 from "./amount.vue?vue&type=style&index=0&id=243097c5&lang=less&scoped=true&"
import style1 from "./amount.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "243097c5",
  null
  
)

export default component.exports